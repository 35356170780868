import http from "@/http/http-common";
const resource = "/api/frontend";
class UtilitesService {
    restaurant() {
        return http.get(`${resource}/restaurant`);
    }
    otherUtilities() {
        return http.get(`${resource}/otherUtilities`);
    }
    // villasDetail(id) {
    //     return http.get(`${resource}/getVillasByType?type_id=${id}`);
    // }

}

export default new UtilitesService();